import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { jwtDecode } from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
  token: any
  private tokenValid = false;
  options: any
  baseUrl: any = environment.baseUrl
  private isAuthenticatedValue = false;

  constructor(private http: HttpClient, private router: Router) {
    
    console.log("TOKEN FROM AUTH")
    this.options = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.token
      }),
    };
  }

  login(data: any) {
    return this.http.post(this.baseUrl + 'login', data)
  }

  isAuthenticated(): boolean {
    const token = sessionStorage.getItem('token') || localStorage.getItem('token');

    if (!token) {
      return false;
    }
    try {
      const decodedToken: any = jwtDecode(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      return decodedToken.exp > currentTimestamp;
    } catch (error) {
      return false;
    }
  }

  logout() {

    return this.http.post(this.baseUrl + 'logout', this.options, this.options)
  } 
}

