import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpClient,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import * as jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  error: any;
  title: string;
  message: string;

  constructor(private http: HttpClient, private router: Router) {}

  handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      switch (error.status) {
        case 0: {
          this.title = 'Servor Error';
          this.message = 'We can not connect to the server';
          break;
        }
        case 500: {
          this.title = 'Server Error';
          this.message = 'Server Error';
          break;
        }
        case 403: {
          this.title = 'Autorisation Error';
          this.message = 'You are not autorised';
          break;
        }
        case 203: {
          this.title = 'Authentification Error';
          this.message = 'You are not authentified';
          break;
        }
        case 400: {
          this.title = 'Bad request Error';
          this.message = 'Bad request';
          break;
        }
        case 404: {
          this.title = 'Not found Error';
          this.message = 'Not found';
          break;
        }
      }
      return throwError(
        `Error status : ${error.status} \n ${operation} failed: ${error.message}`
      );
    };
  }

  private handleHttpError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 203) {
      this.redirectToLogin();
    }

    return throwError(error);
  }

  private redirectToLogin(): void {
    sessionStorage.removeItem('token');
    localStorage.removeItem('token');

    this.router.navigate(['/auth/login']);
  }

  private getToken(): string | null {
    return sessionStorage.getItem('token') || localStorage.getItem('token');
  }

  private setAuthHeaders(headers: HttpHeaders): HttpHeaders {
    const token = this.getToken();

    if (token) {
      return headers.set('Authorization', 'Bearer ' + token);
    }

    return headers;
  }

  get(url: string): Observable<any> {
    const headers = this.setAuthHeaders(new HttpHeaders());

    return this.http
      .get(environment.baseUrl + url, { headers })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }

  post(url: string, data: any): Observable<any> {
    const headers = this.setAuthHeaders(new HttpHeaders());

    return this.http
      .post(environment.baseUrl + url, data, { headers })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }

  put(url: string, data: any): Observable<any> {
    const headers = this.setAuthHeaders(new HttpHeaders());

    return this.http
      .put(environment.baseUrl + url, data, { headers })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }

  delete(url: string): Observable<any> {
    const headers = this.setAuthHeaders(new HttpHeaders());

    return this.http
      .delete(environment.baseUrl + url, { headers })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleHttpError(error))
      );
  }

  download(endPoint: string) {
    return this.http.get(environment.baseUrl + endPoint, {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token'),
        'Content-type': 'application/octet-stream'
      },
      responseType: 'arraybuffer'
    })
    .pipe(
      catchError(this.handleError<[]>('download : ' + endPoint, []))
    );
  }
  
  upload(endPoint: string, data : any)
  {
    return this.http.post(environment.baseUrl + endPoint, data,{
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + localStorage.getItem('token'),
        ContentType: 'multipart/form-data'
      }),
    })
    .pipe(
      catchError(this.handleError<[]>('download : ' + endPoint , []))
    );
  }
  
}
