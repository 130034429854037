import { TablePermission } from './table-permission.interface';

export const tablePermissions: TablePermission[] = [
    {
        tableName: 'Rôles',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Utilisateurs',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Clients',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Dossiers',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Sous-dossiers',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Calendrier des audiences',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Tâches',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Villes',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Procédures',
        permissions: ['index', 'add', 'update', 'delete',],
    },
    {
        tableName: 'Tribunaux',
        permissions: ['index', 'add', 'update', 'delete',],
    },

];