import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RolePermissionService } from 'src/app/service/role-permission.service';
import { RoleService } from 'src/app/service/role.service';

@Component({
  selector: 'app-list-role',
  templateUrl: './list-role.component.html',
  styleUrls: ['./list-role.component.scss'],
})
export class ListRoleComponent implements OnInit {
  public roles: any = [];
  loading: any

  constructor(private roleService: RoleService, private router: Router, private permissionService: RolePermissionService) { }

  public settings = {
    noDataMessage: 'No Data Found',
    mode: 'external',
    actions: {
      delete: true,
    },
    edit: {
      confirmSave: true,
    },
    delete: {
      confirmDelete: true,
    },
    columns: {
      name: {
        title: 'Nom du rôle',
      },
    },
  };

  ngOnInit() {
    this.getAllRoles();
  }

  getAllRoles() {
    this.loading = true
    this.roleService.all().subscribe((res) => {
      this.roles = res;
      console.log('USER : ', this.roles);
      this.roles.forEach((role) => {
        role.role_name = role.role ? role.role.name : '';
      });
      this.loading = false
    });
  }

  async onEditRowSelect(event) { 
    console.log(event);
    this.router.navigate(['/roles/update-role', event?.data?.id]);
  }

  async onDeleteConfirm(event: any) {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ?')) {
      this.loading = true
      this.roleService.delete(event.data.id).subscribe((res) => {
        console.log('RES: ', res);
        this.roles = this.roles.filter(role => role.id !== event.data.id);
        this.permissionService.deleteAll(event.data.id).subscribe(res => {
          this.loading = false
        })
      });
    }
  }
}
