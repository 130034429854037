import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RolePermissionService } from 'src/app/service/role-permission.service';
import { RoleService } from 'src/app/service/role.service';
import { TablePermission } from 'src/app/table-permission.interface';
import { tablePermissions } from 'src/app/table-permissions';

@Component({
  selector: 'app-create-role',
  templateUrl: './create-role.component.html',
  styleUrls: ['./create-role.component.scss'],
})
export class CreateRoleComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public roles: any = [];
  public loading: boolean = false;
  public tablePermissions: TablePermission[] = tablePermissions;
  private permissionDisplayMap = {
    index: 'Afficher',
    add: 'Ajouter',
    update: 'Modifier',
    delete: 'Supprimer'
  };

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private permissionService: RolePermissionService,
    private router: Router,
  ) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  mapPermissionToDisplayName(permissionKey: string): string {
    return this.permissionDisplayMap[permissionKey] || permissionKey;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  createPermissionForm() {
    const formControls = {};
    this.tablePermissions.forEach((tablePermission) => {
      tablePermission.permissions.forEach((permission) => {
        const controlName = this.getTablePermissionControlName(tablePermission.tableName, permission);
        formControls[controlName] = [false];
      });
    });
    this.permissionForm = this.formBuilder.group(formControls);
  }

  ngOnInit() {
  }

  createRoleAndPermissions() {
    if (this.accountForm.valid && this.permissionForm.valid) {
      this.loading = true;
      const roleData = this.accountForm.value;
      const permissionData = this.permissionForm.value;

      this.roleService.add(roleData).subscribe(
        (roleResponse) => {
          const roleId = roleResponse.id;

          Object.entries(permissionData).forEach(([permissionKey, isAllowed]) => {
            if (isAllowed) { 
              const lastDashIndex = permissionKey.lastIndexOf('-');
              let tableName = permissionKey.slice(0, lastDashIndex);
              const permissionName = permissionKey.slice(lastDashIndex + 1);

              tableName = tableName.replace(/\s+/g, '_');

              const permission = {
                role_id: roleId,
                name: `${tableName}-${permissionName}`,
                table_name: tableName,
              };

              this.permissionService.add(permission).subscribe(
                (permissionResponse) => {
                },
                (permissionError) => {
                }
              );
            }
          });
        },
        (roleError) => {
          console.error('Error creating role:', roleError);
        }
      ).add(() => {
        this.router.navigate(['/roles/list-role']);
        this.loading = false;
      });
    }
  }

  getTablePermissionControlId(tableName: string, permission: string): string {
    return `${tableName}-${permission}-control`;
  }

  getTablePermissionControlName(tableName: string, permission: string): string {
    return `${tableName}-${permission}-permission`;
  }
}