import { HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http:HttpService) { }

  all(query: string = null) {
    return this.http.get("users?" + query)
  }

  add(data: any) {
    return this.http.post("users", data)
  }

  get(id: number)
  {
    return this.http.get('users/' + id)
  }

  update(id:number, object: object)
  {
    return this.http.put('users/' + id, object)
  }

  delete(id: number)
  {
    return this.http.delete('users/' + id)
  }
}
