import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { AuthServiceService } from './auth-service.service';
import { jwtDecode } from "jwt-decode";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthServiceService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token =
      sessionStorage.getItem('token') || localStorage.getItem('token');

    if (token) {
      // Decode the token to check its validity and expiration
      try {
        const decodedToken: any = this.decodeToken(token);

        // Check if the token is not expired
        const currentTimestamp = Math.floor(Date.now() / 1000);
        if (decodedToken.exp <= currentTimestamp) {
          // Token expired, redirect to login
          this.authService.logout();
          this.router.navigate(['/auth/login']);
          return throwError('Token expired');
        }

        // Add the token to the request headers
        request = request.clone({
          setHeaders: {
            Authorization: 'Bearer ' + token,
          },
        });

        return next.handle(request);
      } catch (error) {
        // Token decoding or verification failed
        this.authService.logout();
        this.router.navigate(['/auth/login']);
        return throwError('Invalid token');
      }
    }

    return next.handle(request);
  }

  private decodeToken(token: string): any {
    return jwtDecode(token);
  }
}
