<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="card">
        <div class="card-header">
            <h5>User Details</h5>
        </div>
        <div class="card-body">
            <div class="btn-popup pull-right">
                <a class="btn btn-secondary" [routerLink]="'/users/create-user'">Create User</a>
            </div>
            <div id="batchDelete" class="category-table custom-datatable transcation-datatable user-image">
                <div class="text-center" *ngIf="loading">
                    <div class="spinner-border"></div>
                  </div>
                <div *ngIf="!loading" class="table-responsive">
                    <ng2-smart-table
                        [settings]="settings"
                        [source]="users"
                        (editConfirm)="onEditConfirm($event)"
                        (deleteConfirm)="onDeleteConfirm($event)"
                    ></ng2-smart-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->