import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/service/auth-service.service';
import { NavService } from '../../service/nav.service';
import { NotificationService } from 'src/app/service/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public right_sidebar: boolean = false;
  public open: boolean = false;
  public openNav: boolean = false;
  public isOpenMobile: boolean;
  userId: any
  notifications: any
  unreadNotifications: any
  updatedNotification: any

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private authService: AuthServiceService,
    private router: Router,
    private notificationService: NotificationService) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar
    this.rightSidebarEvent.emit(this.right_sidebar)
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }

  logout() {
    this.authService.logout().subscribe(res => {
      localStorage.removeItem('token');
      this.router.navigate(['/auth/login']);
    })
  }

  ngOnInit() {
    this.userId = JSON.parse(localStorage.getItem('user')).id
    this.getAllNotifications()
  }

  getAllNotifications() {
    this.notificationService.all("user_id=" + this.userId + "&sort=-created_at").subscribe(res => {
      this.notifications = res;
      this.unreadNotifications = this.notifications.filter(notification => !notification.is_read);
    })
  }

  updateNotificationStatus(notification) {
    this.updatedNotification = notification;
    this.updatedNotification.is_read = true;
    this.notificationService.update(notification.id, this.updatedNotification).subscribe(res => {
      
    })
  }

}