import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http:HttpService) { }

  all(query: string = null) {
    return this.http.get("notifications?" + query)
  }

  add(data: any) {
    return this.http.post("notifications?", data)
  }

  get(id: number)
  {
    return this.http.get('notifications/' + id)
  }

  getQuery(id: number, query :string = null)
  {
    return this.http.get('notifications/' + id + query) 
  }

  update(id:number, object: object)
  {
    return this.http.put('notifications/' + id, object)
  }

  delete(id: number)
  {
    return this.http.delete('notifications/' + id)
  }
}
