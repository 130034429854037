import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthServiceService } from 'src/app/service/auth-service.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  data: any = {
    email: "",
    password: ""
  }
  errorMessage: string = '';
  loading: any

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthServiceService) {
    this.createLoginForm();
    this.createRegisterForm();
  }

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      userName: [''],
      password: [''],
    })
  }
  createRegisterForm() {
    this.registerForm = this.formBuilder.group({
      userName: [''],
      password: [''],
      confirmPassword: [''],
    })
  }


  ngOnInit() {
    this.loginForm = this.formBuilder.group(
      {
        email: '',
        password: ''
      }
    );
  }

  login() {
    this.loading = true
    console.log("loginForm: ", this.loginForm.value)
    this.authService.login(this.loginForm.value).subscribe(
      (res: any) => {
        localStorage.setItem('token', res.token);
        localStorage.setItem('user', (JSON.stringify(res.user)));
        this.loading = false
        this.router.navigate(['/dashboard/default']);
      },
      (error) => {
        this.loading = false
        this.errorMessage = 'The email or password are incorrect.';
      }
    );
  }
}
