import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RolePermissionService } from 'src/app/service/role-permission.service';
import { RoleService } from 'src/app/service/role.service';
import { TablePermission } from 'src/app/table-permission.interface';
import { tablePermissions } from 'src/app/table-permissions';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-update-role',
  templateUrl: './update-role.component.html',
  styleUrls: ['./update-role.component.scss']
})
export class UpdateRoleComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public roles: any = [];
  public loading: boolean = false;
  public tablePermissions: TablePermission[] = tablePermissions;
  private permissionDisplayMap = {
    index: 'Afficher',
    add: 'Ajouter',
    update: 'Modifier',
    delete: 'Supprimer'
  };
  id: any
  role: any
  permissions: any

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private permissionService: RolePermissionService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  mapPermissionToDisplayName(permissionKey: string): string {
    return this.permissionDisplayMap[permissionKey] || permissionKey;
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

  createPermissionForm() {
    const formControls = {};
    this.tablePermissions.forEach((tablePermission) => {
      tablePermission.permissions.forEach((permission) => {
        const controlName = this.getTablePermissionControlName(tablePermission.tableName, permission);
        formControls[controlName] = [false];
      });
    });
    this.permissionForm = this.formBuilder.group(formControls);
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.id = params['id'];
    });
    this.getRoleWithPermissions();
  }

  getRoleWithPermissions() {
    this.loading = true
    this.roleService.getQuery(this.id, "?with=permissions").subscribe(res => {
      this.role = res;
      this.permissions = res.permissions;

      this.accountForm.patchValue({
        name: this.role.name
      });

      const formControls = {};
      this.permissions.forEach(permission => {
        const [tableName, permissionName] = this.extractTableAndPermissionNames(permission.name);
        const controlName = this.getTablePermissionControlName(tableName, permissionName);
        formControls[controlName] = true;
      });

      this.permissionForm.patchValue(formControls);
      this.loading = false
    });
  }

  extractTableAndPermissionNames(permissionName: string): [string, string] {
    const parts = permissionName.split('-');
    const permissionNameActual = parts.slice(-2, -1)[0];
    const tableName = parts.slice(0, -2).join('-');
    return [tableName, permissionNameActual];
  }

  updateRoleAndPermissions() {
    if (this.accountForm.valid && this.permissionForm.valid) {
      this.loading = true;
      const roleData = this.accountForm.value;
      const permissionData = this.permissionForm.value;
  
      this.roleService.update(this.id, roleData).subscribe(
        (roleResponse) => {
          this.roleService.getQuery(this.id, "?with=permissions").subscribe((res) => {
            const currentPermissions = res.permissions;
  
            const newPermissions = Object.entries(permissionData)
              .filter(([permissionKey, isAllowed]) => isAllowed)
              .map(([permissionKey]) => {
                const lastDashIndex = permissionKey.lastIndexOf('-');
                let tableName = permissionKey.slice(0, lastDashIndex);
                const permissionName = permissionKey.slice(lastDashIndex + 1);
  
                tableName = tableName.replace(/\s+/g, '_');
                return `${tableName}-${permissionName}`;
              });
  
            const permissionsToAdd = newPermissions.filter(
              (perm) => !currentPermissions.some((curPerm) => curPerm.name === perm)
            );
            const permissionsToRemove = currentPermissions.filter(
              (curPerm) => !newPermissions.includes(curPerm.name)
            );
  
            const addPermissionObservables = permissionsToAdd.map((permissionName) => {
              const [tableName, permissionNameActual] = this.extractTableAndPermissionNames(permissionName);
              return this.permissionService.add({
                role_id: this.id,
                name: permissionName,
                table_name: tableName,
              });
            });
  
            const removePermissionObservables = permissionsToRemove.map((curPerm) => {
              return this.permissionService.delete(curPerm.id);
            });
  
            forkJoin([...addPermissionObservables, ...removePermissionObservables]).subscribe(
              () => {
                this.router.navigate(['/roles/list-role']);
                this.loading = false;
              },
              (error) => {
                console.error('Error updating permissions:', error);
                this.loading = false;
              }
            );
          });
        },
        (roleError) => {
          console.error('Error updating role:', roleError);
          this.loading = false;
        }
      );
    }
  }
  
  

  getTablePermissionControlId(tableName: string, permission: string): string {
    return `${tableName}-${permission}-control`;
  }

  getTablePermissionControlName(tableName: string, permission: string): string {
    return `${tableName}-${permission}-permission`;
  }
}
