<div class="page-wrapper">
  <div class="authentication-box">
    <div class="container">
      <div class="row">
        <div class="col-md-7 p-0 card-right" style="margin: 0 auto">
          <div class="card tab2-card">
            <div class="card-body">
              <div class="text-center" *ngIf="loading">
                <div class="spinner-border"></div>
              </div>
              <div class="main-header-left d-none d-lg-block">
                <div class="logo-wrapper">
                  <img
                    class="blur-up lazyloaded"
                    src="\assets\images\advocate-plus-logo.svg"
                    alt=""
                  />
                </div>
              </div>
              <ngb-tabset *ngIf="!loading" class="tab-coupon mb-0">
                <ngb-tab>
                  <ng-template ngbTabTitle
                    ><i class="icon-user mr-2"></i>Login</ng-template
                  >
                  <ng-template ngbTabContent>
                    <div
                      class="tab-pane fade active show"
                      id="account"
                      role="tabpanel"
                      aria-labelledby="account-tab"
                    >
                      <form
                        [formGroup]="loginForm"
                        (ngSubmit)="login()"
                        class="form-horizontal auth-form"
                        novalidate=""
                      >
                        <div class="form-group">
                          <input
                            required=""
                            formControlName="email"
                            name="login[email]"
                            type="email"
                            class="form-control"
                            placeholder="E-mail"
                            id="exampleInputEmail1"
                          />
                        </div>
                        <div class="form-group">
                          <input
                            required=""
                            name="login[password]"
                            formControlName="password"
                            type="password"
                            class="form-control"
                            placeholder="Password"
                          />
                        </div>
                        <!-- <div class="form-terms">
                                                    <div class="custom-control custom-checkbox mr-sm-2">
                                                        <input type="checkbox" class="custom-control-input"
                                                            id="customControlAutosizing">
                                                        <label class="custom-control-label"
                                                            for="customControlAutosizing">Remember me</label>
                                                        <a href="javascript:void(0)"
                                                            class="btn btn-default forgot-pass">lost your
                                                            password</a>
                                                    </div>
                                                </div> -->
                        <div
                          *ngIf="errorMessage"
                          class="error-message"
                          style="color: red"
                        >
                          {{ errorMessage }}
                        </div>
                        <div class="form-button">
                          <button class="btn btn-primary" type="submit">
                            Login
                          </button>
                        </div>
                      </form>
                    </div>
                  </ng-template>
                </ngb-tab>
              </ngb-tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
