import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RoleService } from 'src/app/service/role.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  public accountForm: FormGroup;
  public permissionForm: FormGroup;
  public roles: any = [];
  loading: any

  constructor(
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private userService: UserService,
    private router: Router,
    
  ) {
    this.createAccountForm();
    this.createPermissionForm();
  }

  createAccountForm() {
    this.accountForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      role_id: ['', Validators.required]
    });
  }

  createPermissionForm() {
    this.permissionForm = this.formBuilder.group({});
  }

  ngOnInit() {
    this.getAllroles();
  }

  getAllroles() {
    this.loading = true
    this.roleService.all().subscribe((res) => {
      this.roles = res;
      this.loading = false
    });
  }

  createUser() {
    console.log('je rentre ici');
    if (this.accountForm.valid) {
      this.loading = true
      const userData = this.accountForm.value;
      console.log('USER DATA :', userData);
      this.userService.add(userData).subscribe(
        (response) => {
          console.log('User created successfully:', response);
          this.router.navigate(['/users/list-user']);
          this.loading = false
        },
        (error) => {
          console.error('Error creating user:', error);
        }
      );
    }
  }
}
