<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5>Ajouter un rôle</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Role">
                            <ng-template ngbTabContent>
                                <div class="text-center" *ngIf="loading">
                                    <div class="spinner-border"></div>
                                </div>
                                <div *ngIf="!loading" class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm">
                                        <!-- Name -->
                                        <div class="form-group row">
                                            <label for="name" class="col-xl-3 col-md-4"><span>*</span>
                                                Nom du rôle</label>
                                            <input formControlName="name" id="name"
                                                class="form-control col-xl-8 col-md-7" type="text"
                                                [ngClass]="{ 'is-invalid': accountForm.get('name').invalid && accountForm.get('name').touched }"
                                                required>
                                            <div *ngIf="accountForm.get('name').invalid && accountForm.get('name').touched"
                                                class="invalid-feedback">
                                                <div *ngIf="accountForm.get('name').hasError('required')">Le nom du rôle
                                                    est requis</div>
                                            </div>
                                        </div>
                                    </form>
                                    <form [formGroup]="permissionForm" class="needs-validation role-add" novalidate="">
                                        <div class="permission-block" *ngFor="let tablePermission of tablePermissions">
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">{{ tablePermission.tableName }}</h5>
                                                <div class="row" *ngFor="let permission of tablePermission.permissions">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>{{ mapPermissionToDisplayName(permission) }}</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block"
                                                                [for]="getTablePermissionControlId(tablePermission.tableName, permission)">
                                                                <input class="radio_animated"
                                                                    [id]="getTablePermissionControlId(tablePermission.tableName, permission)"
                                                                    type="checkbox"
                                                                    [formControlName]="getTablePermissionControlName(tablePermission.tableName, permission)">
                                                                
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <!-- Submit Button -->
                                    <div class="pull-right">
                                        <button type="submit" class="btn btn-primary"
                                            (click)="createRoleAndPermissions()">Créer le rôle</button>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-tab>
                    </ngb-tabset>
                    <!-- <div class="pull-right">
                        <button type="button" class="btn btn-primary">Save</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->