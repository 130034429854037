<!-- Container-fluid starts-->
<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">
            <div class="card tab2-card">
                <div class="card-header">
                    <h5> Créer un utilisateur</h5>
                </div>
                <div class="card-body tab2-card">
                    <ngb-tabset class="tab-coupon">
                        <ngb-tab title="Utilisateur">
                            <ng-template ngbTabContent>
                                <div class="text-center" *ngIf="loading">
                                    <div class="spinner-border"></div>
                                  </div>
                                <div *ngIf="!loading" class="tab-pane fade active show" id="account" role="tabpanel"
                                    aria-labelledby="account-tab">
                                    <form [formGroup]="accountForm" (ngSubmit)="createUser()">
                                        <!-- First Name -->
                                        <div class="form-group row">
                                            <label for="first_name" class="col-xl-3 col-md-4"><span>*</span> Prénom
                                                </label>
                                            <input formControlName="first_name" id="first_name"
                                                class="form-control col-xl-8 col-md-7" type="text"
                                                [ngClass]="{ 'is-invalid': accountForm.get('first_name').invalid && accountForm.get('first_name').touched }"
                                                required>
                                            <div *ngIf="accountForm.get('first_name').invalid && accountForm.get('first_name').touched"
                                                class="invalid-feedback">
                                                <div *ngIf="accountForm.get('first_name').hasError('required')">First
                                                    Le prénom est requis</div>
                                            </div>
                                        </div>

                                        <!-- Last Name -->
                                        <div class="form-group row">
                                            <label for="last_name" class="col-xl-3 col-md-4"><span>*</span> 
                                                Nom</label>
                                            <input formControlName="last_name" id="last_name"
                                                class="form-control col-xl-8 col-md-7" type="text"
                                                [ngClass]="{ 'is-invalid': accountForm.get('last_name').invalid && accountForm.get('last_name').touched }"
                                                required>
                                            <div *ngIf="accountForm.get('last_name').invalid && accountForm.get('last_name').touched"
                                                class="invalid-feedback">
                                                <div *ngIf="accountForm.get('last_name').hasError('required')">Le nom est requis</div>
                                            </div>
                                        </div>

                                        <!-- Email -->
                                        <div class="form-group row">
                                            <label for="email" class="col-xl-3 col-md-4"><span>*</span> Email</label>
                                            <input formControlName="email" id="email"
                                                class="form-control col-xl-8 col-md-7" type="email"
                                                [ngClass]="{ 'is-invalid': accountForm.get('email').invalid && accountForm.get('email').touched }"
                                                required>
                                            <div *ngIf="accountForm.get('email').invalid && accountForm.get('email').touched"
                                                class="invalid-feedback">
                                                <div *ngIf="accountForm.get('email').hasError('required')">L'email est requis</div>
                                                <div *ngIf="accountForm.get('email').hasError('email')">Format de l'email invalide</div>
                                            </div>
                                        </div>

                                        <!-- Password -->
                                        <div class="form-group row">
                                            <label for="password" class="col-xl-3 col-md-4"><span>*</span>
                                                Mot de passe</label>
                                            <input formControlName="password" id="password"
                                                class="form-control col-xl-8 col-md-7" type="password"
                                                [ngClass]="{ 'is-invalid': accountForm.get('password').invalid && accountForm.get('password').touched }"
                                                required>
                                            <div *ngIf="accountForm.get('password').invalid && accountForm.get('password').touched"
                                                class="invalid-feedback">
                                                <div *ngIf="accountForm.get('password').hasError('required')">Le mot de passe est requis</div>
                                            </div>
                                        </div>

                                        <!-- Role Dropdown -->
                                        <div class="form-group row">
                                            <label for="validationCustom5" class="col-xl-3 col-md-4"><span>*</span>
                                                Rôle</label>
                                                <select class="form-control col-xl-8 col-md-7" id="validationCustom5"
                                                    formControlName="role_id" required>
                                                    <option value="" disabled selected>Selectionnez un rôle</option>
                                                    <option *ngFor="let role of roles" [value]="role.id">{{ role.name }}
                                                    </option>
                                                </select>
                                                <div *ngIf="accountForm.get('role_id').invalid && accountForm.get('role_id').touched"
                                                    class="invalid-feedback">
                                                    <div *ngIf="accountForm.get('role_id').hasError('required')">Le rôle est requis</div>
                                                </div>
                                        </div>

                                        <!-- Submit Button -->
                                        <div class="pull-right">
                                            <button type="submit" class="btn btn-primary">Créer un utilisateur</button>
                                        </div>
                                    </form>

                                </div>
                            </ng-template>
                        </ngb-tab>
                        <!-- <ngb-tab>
                            <ng-template ngbTabTitle>Permission
                                <ng-template ngbTabContent>
                                    <form [formGroup]="permissionForm" class="needs-validation user-add" novalidate="">
                                        <div class="permission-block">
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">Product Related permission </h5>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Add Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani1">
                                                                <input class="radio_animated" id="edo-ani1" type="radio"
                                                                    name="rdo-ani">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani2">
                                                                <input class="radio_animated" id="edo-ani2" type="radio"
                                                                    name="rdo-ani" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Update Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani3">
                                                                <input class="radio_animated" id="edo-ani3" type="radio"
                                                                    name="rdo-ani1">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani4">
                                                                <input class="radio_animated" id="edo-ani4" type="radio"
                                                                    name="rdo-ani1" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Delete Product</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani5">
                                                                <input class="radio_animated" id="edo-ani5" type="radio"
                                                                    name="rdo-ani2">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani6">
                                                                <input class="radio_animated" id="edo-ani6" type="radio"
                                                                    name="rdo-ani2" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label class="mb-0 sm-label-radio">Apply discount</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated pb-0">
                                                            <label class="d-block mb-0" for="edo-ani7">
                                                                <input class="radio_animated" id="edo-ani7" type="radio"
                                                                    name="rdo-ani3">
                                                                Allow
                                                            </label>
                                                            <label class="d-block mb-0" for="edo-ani8">
                                                                <input class="radio_animated" id="edo-ani8" type="radio"
                                                                    name="rdo-ani3" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="attribute-blocks">
                                                <h5 class="f-w-600 mb-3">Category Related permission </h5>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Add Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani9">
                                                                <input class="radio_animated" id="edo-ani9" type="radio"
                                                                    name="rdo-ani4">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani10">
                                                                <input class="radio_animated" id="edo-ani10"
                                                                    type="radio" name="rdo-ani4" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Update Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani11">
                                                                <input class="radio_animated" id="edo-ani11"
                                                                    type="radio" name="rdo-ani5">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani12">
                                                                <input class="radio_animated" id="edo-ani12"
                                                                    type="radio" name="rdo-ani5" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label>Delete Category</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline mb-0 custom-radio-ml d-flex radio-animated">
                                                            <label class="d-block" for="edo-ani13">
                                                                <input class="radio_animated" id="edo-ani13"
                                                                    type="radio" name="rdo-ani6">
                                                                Allow
                                                            </label>
                                                            <label class="d-block" for="edo-ani14">
                                                                <input class="radio_animated" id="edo-ani14"
                                                                    type="radio" name="rdo-ani6" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-xl-3 col-sm-4">
                                                        <label class="mb-0 sm-label-radio">Apply discount</label>
                                                    </div>
                                                    <div class="col-xl-9 col-sm-8">
                                                        <div
                                                            class="form-group m-checkbox-inline custom-radio-ml d-flex radio-animated pb-0">
                                                            <label class="d-block mb-0" for="edo-ani15">
                                                                <input class="radio_animated" id="edo-ani15"
                                                                    type="radio" name="rdo-ani7">
                                                                Allow
                                                            </label>
                                                            <label class="d-block mb-0" for="edo-ani16">
                                                                <input class="radio_animated" id="edo-ani16"
                                                                    type="radio" name="rdo-ani7" checked="">
                                                                Deny
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </ng-template>
                            </ng-template>
                        </ngb-tab> -->
                    </ngb-tabset>
                    <!-- <div class="pull-right">
                        <button type="button" class="btn btn-primary">Save</button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->