import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss'],
})
export class ListUserComponent implements OnInit {
  public users: any = [];
  loading :any

  constructor(private userService: UserService) {}

  public settings = {
    noDataMessage: 'No Data Found',
    mode: 'inline',
    actions: {
      delete: true,
    },
    edit: {
      confirmSave: true,
    },
    delete: {
      confirmDelete: true,
    },
    columns: {
      first_name: {
        title: 'Prénom',
      },
      last_name: {
        title: 'Nom',
      },
      email: {
        title: 'Email',
      },
      role_name: {
        title: 'Rôle',
      },
    },
  };

  ngOnInit() {
    this.getAllUsers();
  }

  getAllUsers() {
    this.loading = true
    this.userService.all('with=role').subscribe((res) => {
      this.users = res;
      console.log('USER : ', this.users);
      this.users.forEach((user) => {
        user.role_name = user.role ? user.role.name : '';
      });
      this.loading = false
    });
  }

  async onEditConfirm(event: any) {
    if (window.confirm('Are you sure you want to edit?')) {
      console.log('EVENT : ', event);
      this.loading = true
      this.userService.update(event.data.id, event.newData).subscribe((res) => {
        console.log('RES: ', res);
        event.confirm.resolve(event.newData);
        this.loading = false
      });
    }
  }

  async onDeleteConfirm(event: any) {
    if (window.confirm('Are you sure you want to delete?')) {
      this.loading = true
      this.userService.delete(event.data.id).subscribe((res) => {
        console.log('RES: ', res);
        this.users = this.users.filter(user => user.id !== event.data.id);
        event.confirm.resolve(event.newData);
        this.loading = false
      });
    }
  }
}
