import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CreateRoleComponent } from './create-role/create-role.component';
import { ListRoleComponent } from './list-role/list-role.component';
import { UpdateRoleComponent } from './update-role/update-role.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list-role',
        component: ListRoleComponent,
        data: {
          title: "Liste des rôles",
          breadcrumb: "Liste des rôles"
        }
      },
      {
        path: 'create-role',
        component: CreateRoleComponent,
        data: {
          title: "Créer un rôle",
          breadcrumb: "Créer un rôle"
        }
      },
      {
        path: 'update-role/:id',
        component: UpdateRoleComponent,
        data: {
          title: "Modifier un rôle",
          breadcrumb: "Modifier un rôle"
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RolesRoutingModule { }
